<template>
  <div class="">
    <div class="uk-box-shadow-medium uk-background-default uk-padding-small brd12">
      <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid">
        <label><input class="uk-checkbox" type="checkbox" v-model="videoStatus" @change="showToggleVideo">Видео включено</label>
      </div>
      <div class="uk-width-large">
        <div class="uk-text-left uk-margin-top">
          <div class="fnt-bld">Youtube ID Video</div>
          <div class="uk-margin-small-top">
            <input class="uk-input" type="text" v-model="video.youtube_id">
          </div>
          <div v-if="video.youtube_id" class="uk-position-relative">
            <img src="/images/iframe_min.jpg" alt="">
            <iframe class="uk-position-absolute uk-width-1-1 uk-height-1-1 uk-position-top-left" :src="`https://www.youtube.com/embed/${video.youtube_id}`" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
            </iframe>
          </div>
        </div>

        <div class="uk-text-left uk-margin-top">
          <div class="fnt-bld">Название видео</div>
          <div class="uk-margin-small-top">
            <input class="uk-input" type="text" v-model="video.video_name">
          </div>
        </div>

        <div class="uk-text-left uk-margin-top">
          <div class="fnt-bld">Категории</div>
          <div class="uk-form-controls uk-margin-small-top">
            <select class="uk-select" v-model="categories" multiple>
              <option v-for="(category, i) in categories_all" :key="i" :value="category.id_category">
                {{ category.category_name }}
              </option>
            </select>
          </div>
        </div>

        <div class="uk-text-left uk-margin-top">
          <div class="fnt-bld">Дата события</div>
          <div class="uk-margin-top">
            <input
              class="uk-select"
              v-model="video.date_event"
              type="date" data-uk-datepicker="{format:'DD-MM-YYYY'}">
          </div>
        </div>
        <div class="uk-text-left uk-margin-top">
          <div class="fnt-bld">Описание видео</div>
          <div class="uk-margin-small-top">
            <textarea class="uk-padding-small uk-width-1-1 uk-height-medium fs16" v-model="video.video_desc" style="resize: none;"></textarea>
          </div>
        </div>
      </div>

    </div>

    <div class="">
      <button class="btn uk-margin-medium-top bg-church brd36 cl-wh show-animation fs16 uk-box-shadow" @click="saveVideo">
        Сохранить видео
      </button>
    </div>
  </div>
</template>

<script>
import UIkit from 'uikit'

  export default {
    data() {
      return {
        videoStatus: true,
        id: '',
        tags: [],
        categories: [],
        categories_all: [],
        cats: [],
        video: {
          date_event: '',
        },
      }
    },
    created() {
      this.id = this.$route.params.id

      if (this.id) {

        this.$api.post('video/get_cats').then(rs => {
          this.categories_all = rs.data
        })

        this.$api.post('video/get_by_id', {
          id: this.id
        }).then(rs => {
          this.video = rs.data

          this.videoStatus = this.video.status ? true : false

        })

        this.$api.post('video/get_list_categories', {
          id: this.id
        }).then(rs => {
          rs.data.forEach((category, i) => {
            this.categories.push(category.category_id)
          });

        //  this.categories = rs.data
        })

        this.$api.post('video/get_tags_video', {
          id: this.id
        }).then(rs => {
          this.tags = rs.data
        })
      }
    },

    methods: {
      saveVideo() {
        this.$api.post('admin/video/edit', {
          video_id: this.video.video_id,
          date_event: this.video.date_event,
          video_desc: this.video.video_desc,
          video_name: this.video.video_name,
          youtube_id: this.video.youtube_id,
          categories: this.categories,
        })
        .then(rs => {
          UIkit.notification({
            message: 'Слава Господу! Видео успешно обновлено.',
            status: 'success',
            pos: 'top-right',
          });
        })
      },
      showToggleVideo() {
        this.$api.post('admin/video/active_video', {
          video_id: this.video.video_id,
          status: this.videoStatus ? 1 : 0
        })
      }

    }
  }
</script>
